// 存续期检查报告
<template>
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
      <div slot="btn_after">
        <base-button
          icon="iconfont iconzhankai"
          class="foldBtn"
          :class="{ PutawayBtn: fold }"
          type="default"
          @click="fold = !fold"
        ></base-button>
      </div>
    </base-form>
    <!-- 表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
        rowClassName:tableRowClassName
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 文件 -->
        <template slot="fileId" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group
          :data='scope.row'
          :currentManagerId='currentManagerId'
          @previewShow="openPreview"
          @Upload="Upload"
          @deleteTemplate="deleteTemplate"
          @dowload='dowloadFile'
        />
      </template>
      <!-- 先隐藏后期在使用该功能 -->
      <template slot="action" slot-scope="scope" v-if="false">
          <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"
        />
        <icon-button
          @click="edit(scope.row)"
          content="制作"
          icon="iconfont iconbianji3"
        />
      </template>
    </base-table>
      <!-- 文件预览 -->
    <pre-view :fileId="previewData.fileId" :isOpen='true' :fileType="previewData.fileType" :count="preViewcount" />
  </div>
</template>
<script>
// import { downFile } from '@/utils/devUtils'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formConfig, columnsConfig } from './utils/config.js'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { loanApi } from '@/api/businessApi.js'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import FileButtonGroup from './components/file-button-group.vue'
import { getManager, getRoles } from '@/api/sys/user'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import { supplierApi } from '@/api/companyApi'

import PreView from '@/components/pre-view/pre-view.vue'

export default {
  components: { BaseForm, BaseTable, BaseButton, IconButton, FileButtonGroup, PreView },
  props: {},
  data () {
    return {
      fold: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      },
      tableData: [],
      busiManagerOptions: [], // 业务经理
      previewData: {}, // 预览数据
      preViewcount: 0,
      periodNumOptions: [], // 报告期数
      gysData: [], // 供应商数据
      currentManagerId: '' // 当前登录的业务经理Id，判断是否是业务经理并保存Id
    }
  },
  // 计算属性 类似于data概念
  computed: {
    formConfig () {
      return formConfig(this)
    },
    columns () {
      return columnsConfig(this)
    },
    api () {
      return loanApi
    }
  },
  // 监控data中的数据变化
  watch: {
    queryParas: {
      handler (val) {
        if (val.getRoleOK) {
          this.handleFilter()
          this.$set(val, 'getRoleOK', false)
        }
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (!row.isExpire && row.status !== '30') {
        return 'highlight'
      } else {
        return ''
      }
    },
    info () {

    },
    edit () {

    },
    // 文件预览
    openPreview (data) {
      if (data.fileId) {
        this.$set(this.previewData, 'fileId', data.fileId)
        this.$set(this.previewData, 'fileType', data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4))
        this.preViewcount++
      }
    },
    // 查询角色信息
    getRole () {
      getRoles(this.$store.state.user.userInfo.userId).then(res => {
        if (res.data) {
          // 业务经理角色，业务经理条件默认选择用户自己
          if (res.data.some(item => item.roleCode === 'ywjl_code')) {
            this.$set(this.queryParas, 'managerId', this.$store.state.user.userInfo.userId)
            console.log(this.$store.state.user.userInfo)
            this.currentManagerId = this.$store.state.user.userInfo.userId
            // this.currentManagerId = this.$store.state.user.userInfo.nickName
          }
          // 插入值表示接口是否已调用成功
          this.$set(this.queryParas, 'getRoleOK', true)
        }
      })
    },
    // 查询报告期数
    getPeriodNum () {
      this.api.periodNum().then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.periodNumOptions.push({ periodNo: item, periodNoName: item })
          })
        }
      })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 表格查询，改变提交日期
    changeMakeTime (data) {
      if (data) {
        this.$set(this.queryParas, 'makeTimeStart', data[0])
        this.$set(this.queryParas, 'makeTimeEnd', data[1])
      } else {
        this.$set(this.queryParas, 'makeTimeStart', '')
        this.$set(this.queryParas, 'makeTimeEnd', '')
      }
    },

    async  Upload (param, data) {
      const res = await this.UploadFile(param)
      if (!res) {
        return
      }
      // 再调用保存接口,并刷新列表
      const subData = { fileId: res.keyId, fileName: res.fileName, reportInfoId: data.keyId }
      this.api.loanRostReportFileUpload(subData).then(res => {
        if (res.data) {
          this.success('上传成功')
          this.handleFilter()
        }
      })
    },
    // 删除文件
    deleteTemplate (data) {
      this.$stateConfirm({
        title: '提示',
        message: '确认删除该文件吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        this.api.deleteLoanRostReport({ reportInfoId: data.keyId, fileKeyId: data.fileKeyId }).then(res => {
          if (res.data) {
            this.success('删除成功')
            this.handleFilter()
          } else {
            this.warning('删除失败')
          }
        })
      }).catch(() => {

      })
    },
    // 文件下载
    dowloadFile (data) {
      const fileId = data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 上传方法&校验
    async UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g

      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize > 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 获取业务经理下拉
    getBusinessMangeer () {
      getManager({ roleCode: 'ywjl_code' }).then((res) => {
        this.busiManagerOptions = res.data.map((item) => {
          return { busiManagerId: item.userId, busiManagerName: item.nickName }
        })
      })
    },
    handleFilter () {
      this.$nextTick(() => {
        this.queryParas.pageIndex = 1
        this.loadCount++
      })
    },
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.handleFilter()
    this.getBusinessMangeer()
    this.getPeriodNum()
    this.gysList()
    this.getRole()
  }
}
</script>
<style lang='scss' scoped>
.download {
  background: #fff;
  text-align: right;
  padding: 0 21px;
  padding-top: 20px;
  margin-top: 8px;
}
/deep/ .el-cascader {
  width: 100%;
}
/deep/ .highlight {
    .cell{
      color: #F90606;
    }
  }
</style>
