
// import { getDictLists } from '@/filters/fromDict'
// import supplierSelect from '@/components/packages/supplier-select/supplier-select.vue'
import BaseSelect from '@/components/common/base-select/new-base-select'
// import SupplierSelect from '@/components/packages/supplier-select/supplier-select'
import { formatDate } from '@/utils/auth/common'

// 查询
export const formConfig = (content) => {
  return [
    {
      label: '供应商',
      prop: 'companyId',
      attrs: {
        filterable: true,
        clearable: true,
        placeholder: '请选择',
        options: content.gysData,
        selectedField: ['keyId', 'enterpriseName']
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      tag: BaseSelect
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }

    },
    {
      label: '报告期数',
      prop: 'periodNo',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      attrs: {
        filterable: true,
        clearable: true,
        placeholder: '请选择',
        options: content.periodNumOptions,
        selectedField: ['periodNo', 'periodNoName']
      },
      tag: BaseSelect
    },
    {
      label: '报告提交日期',
      prop: 'makeTime',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      isHidden: content.fold,
      attrs: {
        maxlength: '20',
        placeholder: '请输入',
        valueFormat: 'timestamp',
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期'
      },
      tag: 'el-date-picker',
      on: {
        change (data) {
          content.changeMakeTime(data)
        }
      }
    },
    {
      label: '业务经理',
      prop: 'managerId',
      cols: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 },
      isHidden: content.fold,
      attrs: {
        filterable: true,
        clearable: true,
        placeholder: '请选择',
        options: content.busiManagerOptions,
        selectedField: ['busiManagerId', 'busiManagerName']
      },
      tag: BaseSelect
    }
  ]
}
// 表格
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true,
      align: 'right'

    },
    {
      label: '供应商',
      prop: 'companyName',
      'show-overflow-tooltip': true,
      minWidth: '120px',
      align: 'left'
    },
    {
      label: '业务经理',
      prop: 'managerName',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '放款金额（元）',
      prop: 'loanAmount',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.loanAmount ? row.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '起息日',
      prop: 'loanStartTime',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      // align: 'left',
      formatter: row => {
        return formatDate(row.loanStartTime, 'YY-MM-DD')
      }

    },
    {
      label: '到期日(内控)',
      prop: 'loanEndTime',
      minWidth: '120px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.loanEndTime, 'YY-MM-DD')
      }
    },
    {
      label: '报告期数',
      prop: 'periodNo',
      minWidth: '135px',
      'show-overflow-tooltip': true
    },
    {
      label: '报告提交时间',
      prop: 'actualMakeTime',
      minWidth: '135px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.actualMakeTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '文件',
      prop: 'fileId',
      minWidth: '300px',
      HiddenOverflow: true,
      showOverflowTooltip: false
    }
    // {
    //   label: '操作',
    //   prop: 'action',
    //   minWidth: '135px',
    //   'show-overflow-tooltip': true
    // }

  ]
}
